#calculatorComponent {
    background: #FFFFFF;
    border: 1px solid rgba(241, 242, 246, 0.55);
    box-shadow: 0px 8px 16px rgba(218, 218, 218, 0.51);
    border-radius: 6px;
    box-sizing: border-box;
    font-size: 16px;

    strong {
        font-size: 20px;
    }
    
    .range-container{
        font-size: 14px;
    }

    height: 459px;
    right: 0px;

    width: 100%;
    position: inherit;
    @include media-breakpoint-up(lg) {
        width: 428px;
        position: absolute;
    }
}

#sliderContainer {
    background-color: #FBFBFB;
}
