@include media-breakpoint-up(lg) {
    a[role="button"] {
        display: flex !important;
        justify-content: center;
        align-items: center;
    }
}


button, a[role="button"] {

    height: 48px;

    width: 100%;
    @include media-breakpoint-up(sm) {
        width: fit-content;
    }

    &.btn:not(.btn-outline, .btn-link, .btn-secondary) {
        background: linear-gradient(145.97deg, #F18D27 20.16%, #DF2E2E 136.62%);
        border-radius: 6px;
        color: #FFF !important;

    }
    &.btn {
        &:focus {
            outline: 0;
            box-shadow: none;
        }
    }
    &.btn-outline {
        border-color: #F18D27;
        padding: 0.375rem 1.5rem;
        font-weight: 700;

        &:hover {
            background-color: #F18D27;
        }
    }
    &.btn-link {
        color: $default-font-color;
        &:hover {
            color: $default-link-color;
        }
    }

    &.btn-secondary {
        background: #FFFFFF !important;
        color: #F18D27 !important;
        border-radius: 6px;
        border-color: #F18D27;
    }

}

#calculatorComponent, #companyData {
    button, a[role="button"] {
        width: 275px;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}