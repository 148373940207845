#companyData {
    padding-bottom: 100px;

    @include media-breakpoint-up(sm) {
        padding-bottom: 200px;
    }
}

#slider-section {
    padding-top: 0;

    @include media-breakpoint-up(md) {
        padding-top: 50px;
    }

    @include media-breakpoint-up(lg) {
        padding-top: 150px;
    }
}

.main-banner {
    width: 100%;
    max-width: 1400px;
    aspect-ratio: 12/5;
}

section {
    //padding: 104px 0px 120px 0px;
    padding-bottom: 50px;

    @include media-breakpoint-up(sm) {
        padding-bottom: 75px;
    }

    &:nth-child(odd) {
        background-color: $default-background-color;
    }
    &:nth-child(even) {
        background-color: $secondary-background-color;
    }

    a {
        color: $default-link-color;
        &:hover {
            color: $default-link-color;
        }
        strong {
            font-weight: 700;
            text-decoration: underline;

        }
    }
}

div.copyright {
    background-color: $secondary-background-color;
    a {
        font-weight: bolder;
    }
}

footer {
    background-color: $secondary-background-color;
    a {
        &:hover {
            color: $default-link-color;
        }
    }
}

#introCredifast {
    padding-top: 0px;
    @include media-breakpoint-up(sm) {
        padding-top: 110px;
    }
}

.main-person-image {
    width: 100%;
    @include media-breakpoint-up(sm) {
        width: auto;
    }
}

#sectionProducts {
    img {
        width: 100%;
        @include media-breakpoint-up(sm) {
            width: auto;
        }
    }

    button, a[role="button"] {
        width: 181px;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}

#officesPage {
    img {
        width: 100%;
        @include media-breakpoint-up(sm) {
            width: auto;
        }
    }

    button, a[role="button"] {
        width: auto;

        @include media-breakpoint-down(sm) {
            width: 100%;
        }
    }
}

#contactFormSection {
    table {
        font-size: 12px;
        @include media-breakpoint-up(sm) {
            font-size: 1rem;
        }
    }
}



#companyData {
    color: #FFF;

    //min-height: 800px;

    img {
        height: 600px;
        @include media-breakpoint-up(md) {
            height: unset;
        }
    }

    .company-data {
        position: absolute;
        top: 70px;
        @include media-breakpoint-up(md) {
            top: 130px;
        }
        @include media-breakpoint-up(lg) {
            top: 320px;
        }
    }

    img.people-picture {
        content: url("/assets/landing/img/small/section-7.png");
        @include media-breakpoint-up(md) {
            content: url("/assets/landing/img/big/section-7.png");
        }
    }

    .container {
        img {
            opacity: 0.5;
            background: linear-gradient(145.97deg, rgba(241, 141, 39, 0.6) 20.16%, rgba(223, 46, 46, 0.6) 136.62%);
        }
    }

    strong {
        font-size: 36px;
        font-weight: 400;
        font-family: 'Arial Rounded MT Bold';
    }
    .data-boxes {

        align-items: flex-end;
        .data-box {

            min-height: 150px;
            font-size: 14px;
            background: linear-gradient(119.5deg, #F18D27 0.36%, #FF5C00 101.17%);
            border-radius: 6px;
            strong {
                font-size: 32px;
            }

            @include media-breakpoint-up(md) {
                font-size: 16px;
                strong {
                    font-size: 42px;
                }
            }
        }
        
        flex-direction: column;
        
        @include media-breakpoint-up(md) {
            flex-direction: unset;
        }
    }
    
}