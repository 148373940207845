
.modal-image {
    content: url("/assets/landing/img/big/Mesa de trabajo 29.png");
}

.modal-text {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: inherit;
    background: linear-gradient(119.5deg, #F18D27 0.36%, #FF5C00 101.17%);
}

.banner-footer {
    font-size: 11px;
    bottom: 0px;
}

.banner-circle {
    font-weight: 600;
    font-family: 'Inter';
    font-size: 14px;
    text-align: center;
    width: 235px;
    height: 235px;
    position: absolute;
    top: 13px;
    left: 0px;
    border-radius: 150px;
    z-index: 1000;
    background: linear-gradient(145.97deg, #F18D27 20.16%, #DF2E2E 136.62%);
    box-shadow: 0px 6.47164px 12.9433px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-up(sm) {
        left: 25px;
    }    
}

.modal-close {
    cursor: pointer;
    path {
        fill: black;
    }
}

#bannerModal {
    min-width: 300px;
}

.modal-open {
    overflow: scroll;
}