#accordionPage {
    .card-header {
        background-color: #FFF;
    }

    .card-body {
        background-color: #FFF;
    }
}

.card-text {
    ul {
        padding-left: 0px;
        list-style-type: none;
    }
}

#aboutUsSection {
    .about-us-text-description {
        position: inline;

        @include media-breakpoint-up(md) {
            position: absolute;
        }
    }
}

//#region offices

.sucursal {
    background: #FFFFFF;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 6px;
    padding: 0 !important;
    margin: 0;
    max-width: 400px;
}

.product-item-sucursal {
    margin: 0 !important;
}

.sucursales {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

#officePage {
    background: #FAFBFB;
}

//#endregion

//#region section-2

.card-header {
    padding: 0 !important;
    padding-top: 12px !important;
}

.custom-card-text {
    white-space: nowrap;
    display: block;
    text-align: center;
}

.custom-card-body {
    padding-left: 0;
    padding-right: 0;
}

//#endregion

//#region section-3

.left-image {
    width: 288px;
    height: 299px;
    object-fit: cover;
    border-radius: 6px 0 0 6px;
}

.right-image {
    width: 532px;
    height: 310px;
    object-fit: cover;
    border-radius: 6px 6px 0 0;
}

.right-box {
    background: #FFFFFF;
    border-radius: 0 0 6px 6px;
}

@media only screen and (max-width: 991px) {
    .left-image {
        border-radius: 6px 6px 0 0;
    }
}

//#endregion

//#region section-7

.people-picture-container {
    height: 523px;
    background: linear-gradient(145.97deg, rgba(241, 141, 39, 0.6) 20.16%, rgba(223, 46, 46, 0.6) 136.62%);
    border-radius: 6px;
}

.people-picture-container-main {
    height: 100%;
    width: 100%;
    border-radius: inherit;

    display: flex;
    justify-content: center;
    align-items: center;
}

.people-picture-container-main::before {
    content: "";
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.75;
    border-radius: inherit;
}

.data-boxes > div {
    padding: 0 10px !important;
}

.data-boxes p {
    margin: 0;
}

.data-box {
    width: 100%;
    max-width: 301px;
    height: 200px;
    padding: 20px !important;
    padding-top: 30px !important;
    margin: auto !important;
}

@media (min-width: 767px) {
    #companyData .company-data {
        top: 300px;
    }
}

@media (max-width: 767px) {
    #companyData .company-data {
        top: 0 !important;
        height: 100% !important;
    }

    .data-box {
        height: 120px;
    }

    .data-boxes {
        height: 100%;
        flex-direction: column !important;
        flex-wrap: nowrap !important;
        justify-content: space-evenly !important;
    }

    .data-boxes > div {
        height: fit-content !important;
        max-height: unset !important;
        flex: 0 0 0 !important;
    }
}

//#endregion

//#region about-us

.about-us-heading {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 13px;
    margin-top: 25px;
    color: #939393;
}

.about-us-title {
    font-family: 'Arial Rounded MT Bold', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 18px;
    color: #404040;
}

.about-us-body {
    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #404040;
}

.about-us-box {
    min-height: 475px;
}

//#endregion

//#region faqs

.collapsable-btn {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

svg {
    transition: all .5s;
    height: 100%;
}

button:not(.collapsed) > svg {
    transform: rotate(180deg);
}

.orange-bg {
    background: linear-gradient(145.97deg, rgba(241, 141, 39, 0.6) 20.16%, rgba(223, 46, 46, 0.6) 136.62%);
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.orange-image {
    height: 100%;
    width: 100%;
    border-radius: inherit;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.orange-image::before {
    content: "";
    background-size: cover;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.75;
    border-radius: inherit;
}

.orange-image::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.25);
}

.orange-title {
    font-family: 'Arial Rounded MT Bold', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 36px;
    text-align: center;
    color: #FFFFFF;
    z-index: 10;
}

.orange-btn {
    background: #FFFFFF;
    border-radius: 6px;
    padding: 5px 30px;

    font-family: 'Inter', serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;

    z-index: 10;
    color: #F18D27;
}

.orange-btn:hover {
    text-decoration: none;
    color: #F18D27;
}

//#endregion

//#region section-contact

.text-input {
    border: 1px solid #C0C0C0;
    border-radius: 6px;
    padding: 23px 12px;
}

.text-input::placeholder {
    color: #C0C0C0;
}

.contact-form label {
    font-weight: bolder;
    margin-left: 13px;
}

.contact-info svg {
    width: 23px;
    height: 23px;
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact-info > div {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.contact-send-btn {
    width: 100%;
    height: 48px;
}

@media only screen and (min-width: 767px) {
    .contact-send-btn {
        width: 157px;
    }
}

//#endregion
