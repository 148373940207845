div.card {
    border: none;
    font-size: $font-size-text-small;
    
    @include media-breakpoint-up(md) {
        font-size: $font-size-text-big;
    }
    .card-header {
        font-size: 16px;
        padding-left: 0px !important;
        padding-bottom: 0px !important;
        margin-bottom: 0px !important;
        border-bottom: 1px solid #C0C0C0;
        background-color: $secondary-background-color;
        button {
            padding-left: 0px;
        }
    }
    .card-body {
        font-size: 14px;
        background-color: $secondary-background-color;
    }
}

.card-title {
    font-weight: 600;
    font-size: $font-size-title-small;
    @include media-breakpoint-up(md) {
        font-size: $font-size-title-big;
    }
}

#sectionProducts, #officesPage {
    div.card, div.product-item {
        font-size: 16px;
        filter: drop-shadow(0px 8px 16px rgba(0, 0, 0, 0.08));
        background-color: #FFF;
        .card-header {
            border-bottom: none;
        }
    }
}

#sectionAdvantages {
    div.card {
        .card-header {
            background-color: #FFF;
            border-bottom: none;
        }
        .card-body {
            background-color: #FFF;
        }
    }
    .btn
        &:focus {
            box-shadow: none;
        }
}