@import "~bootstrap/scss/bootstrap";
@import "~bootstrap/scss/bootstrap-grid";

@font-face {
    font-family: 'Arial Rounded MT Bold';
    src: url('../fonts/arial-rounded-mt-bold.ttf');
}

@font-face {
    font-family: 'Inter';
    src: url('../fonts/inter.ttf');
}

$default-background-color: #FFFFFF;
$secondary-background-color: #FAFBFB;

$default-font-color: #404040;
$default-link-color: #F18D27;

$font-size-section-title-big: 36px;
$font-size-section-title-small: 22px;

$font-size-title-big: 26px;
$font-size-title-small: 16px;

$font-size-text-big: 18px;
$font-size-text-small: 14px;

body {
    min-width: 242px;
    background-color: $default-background-color;
    color: $default-font-color;
    font-family: 'Inter', serif;
}

header {
    height: 135px;
    background-color: red;
}

h1 {
    font-family: 'Arial Rounded MT Bold';
    font-size: 48px;
}

h2 {
    font-family: 'Arial Rounded MT Bold';
    font-weight: 400;
    font-size: $font-size-section-title-small;

    @include media-breakpoint-up(md) {
        font-size: $font-size-section-title-big;
    }
}

h5 {
    font-family: 'Arial Rounded MT Bold';
    font-size: 18px;
    font-weight: 400;
    @include media-breakpoint-up(sm) {
        font-size: 26px;
    }
}

p {
    font-size: 14px;
    @include media-breakpoint-up(sm) {
        font-size: 16px;
    }
}

a {
    color: $default-font-color;
}
