
.navbar {
    background-color: $secondary-background-color;
    box-shadow: 0px 2px 20px -10px grey;
    height: 135px;
    font-size: $font-size-title-small;
    font-weight: 600;
    min-width: 240px;
    
    @include media-breakpoint-down(md) {
        #navbarResponsive {
            position: fixed;
            top: 0;
            left: 0;
            z-index: 1;
            width: 296px;
            height: 100%;
            background-color: #F26718;
            
            a {
                color: white;
            }
        }
    }
    

    a {
        &:hover {
            color: $default-link-color;
        }
    }
}

.navbar-toggler {
    max-width: 50px;
}
